<style lang="less" scoped>
@import "./ManageCampus";
</style>
<template>
  <div>
    <Modal v-model="deleteModal" width="360">
      <p slot="header" style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>是否确认删除</span>
      </p>
      <div style="text-align: center">
        <p>此删除为非关联删除，不会删除关联的题目</p>
        <p>是否确认继续?</p>
      </div>
      <div slot="footer">
        <Button
          type="error"
          size="large"
          long
          :loading="deleting"
          @click="deleteUnit"
          >Delete</Button
        >
      </div>
    </Modal>
    <Card class="info-card">
      <Row class="campus-info">
        <Col span="8">
          <img class="campus-image" :src="campusImageUrl" />
        </Col>
        <Col span="16" class="right-info">
          <Row>
            校区名称:
            <span>
              {{ campus }}
            </span>
          </Row>
          <Row>
            当前使用的管理员:
            <span>
              {{ user.username }}
            </span>
          </Row>
          <Row>
            附属学院:
            <span>
              {{ facultyList.join(", ") }}
            </span>
          </Row>
          <Row>
            已激活学期:
            <span>
              {{ actived_semesters.join(", ") }}
            </span>
          </Row>
        </Col>
      </Row>
    </Card>
    <Card class="management-card">
      <p></p>
      <Tabs type="card" @on-click="clickTabHandler" :animated="false">
        <TabPane label="学期" name="semesters">
          <!-- 管理学期页面 -->
          <div>
            <Row class="manage-sem" :gutter="40">
              <Col span="7" :lg="7" class="activited-sem-list">
                <Card dis-hover>
                  <span class="title">当前学期：</span>
                  <CellGroup>
                    <Cell
                      v-for="(courses, sem) in activedSemestersWithDict"
                      :title="sem"
                      :key="sem"
                      :to="'/manage-units?semester=' + sem"
                    >
                      <Badge
                        v-if="courses !== undefined"
                        :show-zero="true"
                        class-name="blue-badge"
                        :count="courses.length"
                        slot="extra"
                      />
                    </Cell>
                  </CellGroup>
                </Card>
              </Col>
              <Col span="24" :lg="17">
                <Row class="transfer-group">
                  <Col span="19">
                    <Transfer
                      :data="semestersTransferData"
                      :target-keys="transferRightKeys"
                      :list-style="semTransferListStyle"
                      :operations="['激活', '归档']"
                      :titles="['已激活', '未激活']"
                      filterable
                      @on-change="semTransferHandler"
                      @on-selected-change="semTransferSelectedHandler"
                    >
                    </Transfer>
                  </Col>
                  <Col span="5" class="create-sem-btn">
                    <Row>
                      <Button @click="createSemDraw = true" type="primary">
                        &lt; 创建学期
                      </Button>
                    </Row>
                    <Row>
                      <Button @click="showDestroySemDraw" type="error">
                        销毁学期 &gt;
                      </Button>
                    </Row>
                    <Drawer
                      title="创建新学期"
                      v-model="createSemDraw"
                      width="640"
                      :mask-closable="false"
                    >
                      <Form class="new-sem-form">
                        <Row>
                          <Col span="16">
                            <img class="campus-image" :src="campusImageUrl" />
                          </Col>
                        </Row>
                        <Row>
                          <FormItem label="学期编号:">
                            <Input
                              v-model="semCode"
                              placeholder="请输入学期编号"
                            />
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem label="年份:">
                            <Input
                              v-model="semYear"
                              placeholder="学期编号前4位数字表示年份"
                              disabled
                            />
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem label="学期名称:">
                            <Input
                              v-model="semName"
                              placeholder="学期编号4位以后表示名称"
                              disabled
                            />
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem
                            label="编号状态:"
                            class="new-sem-code-status"
                          >
                            <div v-if="semCode.length > 0">
                              <p
                                v-if="Object.keys(semesters).includes(semCode)"
                              >
                                已存在
                              </p>
                              <p
                                v-else-if="
                                  semYear.length == 0 || semName.length == 0
                                "
                              >
                                不合法
                              </p>
                              <p v-else class="ok">可用</p>
                            </div>
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem label="Note:">
                            <p>学期创建后将处于未激活状态</p>
                          </FormItem>
                        </Row>
                      </Form>
                      <div class="create-sem-drawer-footer">
                        <Button
                          style="margin-right: 8px"
                          @click="cancelCreateSem()"
                          >取消</Button
                        >
                        <Button
                          type="primary"
                          @click="createSem()"
                          :disabled="!isNewSemValid"
                          >创建学期</Button
                        >
                      </div>
                    </Drawer>
                    <Drawer
                      title="销毁学期"
                      v-model="destroySemDraw"
                      width="640"
                      :mask-closable="false"
                    >
                      <Form class="new-sem-form">
                        <Row>
                          <Col span="16">
                            <img class="campus-image" :src="campusImageUrl" />
                          </Col>
                        </Row>
                        <Row>
                          <FormItem label="学期编号:">
                            <Input v-model="semCode" disabled />
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem label="年份:">
                            <Input
                              v-model="semYear"
                              placeholder="学期编号前4位数字表示年份"
                              disabled
                            />
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem label="学期名称:">
                            <Input
                              v-model="semName"
                              placeholder="学期编号4位以后表示名称"
                              disabled
                            />
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem
                            label="关联课程:"
                            v-if="semCourseDict[semCode] != undefined"
                          >
                            <p v-if="semCourseDict[semCode].length == 0">
                              无关联课程，可安全删除
                            </p>
                            <p v-else class="red">
                              {{ semCourseDict[semCode].join(", ") }}
                            </p>
                          </FormItem>
                        </Row>
                        <Row>
                          <FormItem label="Note:">
                            <p>
                              删除学期将导致所有关联课程全部被删除，请慎重操作
                            </p>
                          </FormItem>
                        </Row>
                      </Form>
                      <div class="create-sem-drawer-footer">
                        <Button
                          style="margin-right: 8px"
                          @click="cancelOperateSem()"
                          >取消</Button
                        >
                        <Button type="error" @click="destroySem()">销毁</Button>
                      </div>
                    </Drawer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </TabPane>
        <TabPane label="课程类型" name="course_types">
          <Row :gutter="40">
            <Col span="7" class="left-cell">
              <Card dis-hover :padding="0" title="课程类型">
                <CellGroup @on-click="clickTypeCell">
                  <Cell
                    v-for="(course_type, type_code) in courseTypes"
                    :key="type_code"
                    :name="type_code"
                    :selected="type_code == selectedCourseType"
                    :title="course_type"
                    :label="type_code"
                    extra="详细 >"
                  >
                  </Cell>
                  <Cell name="newCourse" :selected="createTypeCard">
                    新建课程类型
                  </Cell>
                </CellGroup>
              </Card>
            </Col>
            <Col span="6" v-if="courseTypeCard" class="course-type-card">
              <Card>
                <p>
                  <span class="left"> 课程类型:</span>
                  <span>{{ courseTypes[selectedCourseType] }}</span>
                </p>
                <p>
                  <span class="left">对应编号:</span>
                  <span>{{ selectedCourseType }}</span>
                </p>
                <p>
                  <Button type="error" @click="deleteCourseType">删除</Button>
                  <Button
                    type="primary"
                    :to="'/manage-units?course_type=' + selectedCourseType"
                    >跳转</Button
                  >
                </p>
              </Card>
            </Col>
            <Col span="6" v-if="createTypeCard">
              <Card>
                <Form
                  :model="newCourseTypeForm"
                  :label-width="80"
                  class="new-course-type-form"
                >
                  <FormItem label="课程类型">
                    <Input
                      v-model="newCourseTypeForm.course_type"
                      placeholder="平时班"
                    >
                    </Input>
                  </FormItem>
                  <FormItem label="对应编号">
                    <Input
                      v-model="newCourseTypeForm.type_code"
                      placeholder="psb"
                    >
                    </Input>
                  </FormItem>
                  <FormItem label="示例编码">
                    <span class="gray"
                      >FIT9133{{ newCourseTypeForm.type_code }}2021S1</span
                    >
                  </FormItem>
                  <FormItem>
                    <Button type="primary" @click="createCourseType"
                      >创建</Button
                    >
                  </FormItem>
                </Form>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane label="科目" name="unit_codes">
          <Row :gutter="40">
            <Col span="7" class="left-cell">
              <Card dis-hover :padding="0" title="学院">
                <CellGroup @on-click="clickFacultyCell">
                  <Cell
                    v-for="faculty in facultyList"
                    :key="faculty"
                    :name="faculty"
                    :selected="faculty == selectedFaculty"
                    :label="faculty"
                    extra="更多 >"
                  >
                  </Cell>
                </CellGroup>
              </Card>
            </Col>
            <Col span="7" class="left-cell" v-if="selectedFaculty">
              <Card dis-hover :padding="0" title="科目">
                <CellGroup @on-click="clickUnitCell">
                  <Cell
                    v-for="(unit_name, unit_code) in unitCodes"
                    :key="unit_code"
                    :name="unit_code"
                    :title="unit_name"
                    :selected="unit_code == selectedUnit"
                    :label="unit_code"
                    class="wrap-text"
                    extra="详情 >"
                  >
                  </Cell>
                  <Cell name="createUnit" :selected="createUnitCard">
                    新建科目
                  </Cell>
                </CellGroup>
              </Card>
            </Col>
            <Col span="10" v-if="selectedUnit" class="left-cell">
              <Card dis-hover :padding="0">
                <div slot="title">
                  <p>{{ selectedUnit }} (课程数量: {{ courseList.length }})</p>
                  {{ unitCodes[selectedUnit] }}
                </div>
                <CellGroup @on-click="clickCourseCell">
                  <Cell
                    v-for="course in courseList"
                    :title="course.course_name"
                    :key="course.course_code"
                    :name="course.course_code"
                    :label="course.course_code"
                    :to="'/unitInfo?id=' + course.course_code"
                    extra="跳转"
                  >
                  </Cell>
                  <Cell name="delete" class="delete-cell"> 删除该科目 </Cell>
                </CellGroup>
              </Card>
            </Col>
            <Col span="10" v-if="createUnitCard">
              <Card>
                <Form
                  :model="newUnitCodeForm"
                  :label-width="80"
                  class="new-course-type-form"
                >
                  <FormItem label="课程类型">
                    <Input
                      v-model="newUnitCodeForm.unit_name"
                      placeholder="Python"
                    >
                    </Input>
                  </FormItem>
                  <FormItem label="对应编号">
                    <Input
                      v-model="newUnitCodeForm.unit_code"
                      placeholder="FIT9136"
                    >
                    </Input>
                  </FormItem>
                  <FormItem label="示例编码">
                    <span class="gray"
                      >{{ newUnitCodeForm.unit_code }}psb2021S1</span
                    >
                  </FormItem>
                  <FormItem>
                    <Button type="primary" @click="createUnitCode">创建</Button>
                  </FormItem>
                </Form>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Card>
  </div>
</template>
<script>
import monashIT from "../../assets/monash.jpeg";
import melbUniIT from "../../assets/melb_uni_IT.jpeg";
export default {
  data() {
    return {
      deleteModal: false,
      deleting: false,
      campus: "",
      semesters: {},
      courseList: [],
      facultyList: ["加载中"],
      createSemDraw: false,
      destroySemDraw: false,
      semestersLoading: true,
      courseTypesLoading: true,
      semTransferListStyle: {
        width: "40%"
      },
      courseTypes: {},
      selectedFaculty: "",
      unitCodes: {},
      selectedUnit: "",
      selectedCourseType: "",
      courseTypeCard: false,
      createTypeCard: false,
      createUnitCard: false,
      newCourseTypeForm: {},
      newUnitCodeForm: {},
      semCode: "",
      selectedRightKeys: [],
      semCourseDict: {}
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.getCurrentUser;
    },
    actived_semesters: function () {
      const sem_list = [];
      for (const sem in this.semesters) {
        if (this.semesters[sem]) {
          sem_list.push(sem);
        }
      }
      if (sem_list.length == 0) {
        return ["加载中"];
      }
      return sem_list;
    },
    activedSemestersWithDict: function () {
      const sem_dict = {};
      for (const sem in this.semesters) {
        if (this.semesters[sem]) {
          sem_dict[sem] = this.semCourseDict[sem];
        }
      }
      return sem_dict;
    },
    campusImageUrl: function () {
      if (this.campus == "Monash" || this.campus == "Monash University") {
        return monashIT;
      } else if (this.campus == "Univerysity of Melbourne") {
        return melbUniIT;
      }
      return "";
    },
    transferRightKeys: function () {
      const target_keys = this.semestersTransferData
        .filter(sem_dict => {
          return sem_dict.is_active == false;
        })
        .map(item => item.key);
      return target_keys;
    },
    semestersTransferData: function () {
      const sem_list = [];
      for (const sem in this.semesters) {
        sem_list.push({
          key: sem,
          label: sem,
          is_active: this.semesters[sem]
        });
      }
      return sem_list;
    },
    semYear: function () {
      const year = this.semCode.slice(0, 4);
      if (!isNaN(Number(year))) {
        return year;
      }
      return "";
    },
    semName: function () {
      return this.semCode.slice(4);
    },
    isNewSemValid: function () {
      if (
        this.semYear.length > 0 &&
        this.semName.length > 0 &&
        !Object.keys(this.semesters).includes(this.semCode)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    semTransferHandler: function (transferSems) {
      this.$Loading.start();
      let changeCount = 0;
      // transferSems contains semesters should be disactive.
      Object.keys(this.semesters).map(sem => {
        const target_status = !transferSems.includes(sem);
        const loading = this.$Loading;
        if (this.semesters[sem] !== target_status) {
          // if status needs to be change
          const req_dict = { active: target_status };
          changeCount += 1;
          this.axios
            .put(`api/campus/${this.campus}/semester/${sem}`, req_dict)
            .catch(error => {
              this.$Notice.error({ title: "切换学期状态失败", desc: error });
            })
            .then(() => {
              //always execute
              changeCount -= 1;
              if (changeCount === 0) {
                this.refreshSemesters();
                loading.finish();
                this.selectedRightKeys = [];
              }
            });
        }
      });
    },
    semTransferSelectedHandler: function (
      sourceSelectedKeys,
      targetSelectedKeys
    ) {
      this.selectedRightKeys = targetSelectedKeys;
    },
    cancelOperateSem: function () {
      this.createSemDraw = false;
      this.destroySemDraw = false;
      this.semCode = "";
    },
    createSem: function () {
      this.axios
        .post(`api/campus/${this.campus}/semesters`, this.semCode)
        .then(resp => {
          this.semesters = resp.data;
          this.$Notice.success({
            title: "创建新学期成功",
            desc: `${this.semCode} 创建成功`
          });
          this.semCode = "";
          this.createSemDraw = false;
        })
        .catch(error => {
          this.$Notice.error({ title: "切换学期状态失败", desc: error });
        });
    },
    showDestroySemDraw: function () {
      if (this.selectedRightKeys.length === 1) {
        this.destroySemDraw = true;
        this.semCode = this.selectedRightKeys[0];
      } else if (this.selectedRightKeys.length === 0) {
        this.$Notice.error({
          title: "请选择要销毁的学期",
          desc: "请选择要销毁的学期"
        });
      } else {
        this.$Notice.error({
          title: "只能选择一个学期",
          desc: "只能选择一个学期"
        });
      }
    },
    destroySem: function () {
      this.axios
        .delete(`api/campus/${this.campus}/semester/${this.semCode}`)
        .then(resp => {
          this.$Notice.success({
            title: "成功",
            desc: `学期${this.semCode}销毁成功`
          });
        })
        .catch(error => {
          this.$Notice.error({ title: "销毁失败", desc: error });
        })
        .then(() => {
          this.destroySemDraw = false;
          this.refreshSemesters();
        });
    },
    refreshSemesters: function () {
      this.semestersLoading = true;
      let waitingCount = 0;
      const newSemCourseDict = {};
      this.axios.get(`api/campus/${this.campus}/semesters`).then(resp => {
        this.semesters = resp.data;
        this.axios.get(`api/campus/${this.campus}/faculties`).then(resp => {
          this.facultyList = resp.data;
          // 更新每学院对应课程数量
          this.facultyList.map(faculty => {
            // 每学院
            Object.keys(this.semesters).map(sem => {
              // 每学期
              waitingCount += 1;
              this.axios
                .get(
                  `api/campus/${this.campus}/faculty/${faculty}/courses?semester=${sem}`
                )
                .then(resp => {
                  newSemCourseDict[sem] = resp.data.map(c => c.course_code);
                })
                .then(() => {
                  waitingCount -= 1;
                  if (waitingCount == 0) {
                    this.semCourseDict = newSemCourseDict;
                    this.$nextTick(() => {
                      this.semestersLoading = false;
                    });
                  }
                });
            });
          });
        });
      });
    },
    clickTabHandler: function (name) {
      if (name == "semesters") {
        this.refreshSemesters();
      } else if (name == "course_types") {
        this.refreshCourseTypes();
      } else if (name == "unit_codes") {
        return;
      }
    },
    refreshCourseTypes: function () {
      this.axios.get(`api/campus/${this.campus}/course_types`).then(resp => {
        this.courseTypes = resp.data;
      });
    },
    clickTypeCell: function (cellName) {
      if (cellName == "newCourse") {
        this.newCourseTypeForm = {};
        this.createTypeCard = true;
        this.courseTypeCard = false;
        this.selectedCourseType = "";
      } else {
        this.createTypeCard = false;
        this.courseTypeCard = true;
        this.selectedCourseType = cellName;
      }
    },
    refreshUnitCodes: function (targetUnit) {
      this.axios
        .get(`api/campus/${this.campus}/faculty/${this.selectedFaculty}/units`)
        .then(resp => {
          this.unitCodes = resp.data;
          this.selectedUnit = targetUnit || "";
        })
        .catch(e => {
          this.$Message.error("获取信息失败");
        });
    },
    clickFacultyCell: function (cellName) {
      this.selectedFaculty = cellName;
      this.refreshUnitCodes();
    },
    clickUnitCell: function (cellName) {
      if (cellName == "createUnit") {
        this.createUnitCard = true;
        this.selectedUnit = "";
      } else {
        this.$Loading.start();
        const params = { codes: cellName, semester: "all" };
        this.api.course.courseList
          .get(this.selectedFaculty, params)
          .then(resp => {
            this.$Loading.finish();
            this.createUnitCard = false;
            this.selectedUnit = cellName;
            this.courseList = resp.data;
          })
          .catch(() => {});
      }
    },
    clickCourseCell: function (cellName) {
      if (cellName == "delete") {
        this.deleteModal = true;
      } else {
        this.route;
      }
    },
    deleteUnit: function () {
      this.deleting = true;
      this.axios
        .delete(
          `api/campus/${this.campus}/faculty/${this.selectedFaculty}/unit/${this.selectedUnit}`
        )
        .then(resp => {
          this.$Message.success("删除成功");
        })
        .catch(e => {
          if (e.status_code == 404) {
            this.$Message.error("课程不存在" + e);
          } else {
            this.$Message.error("删除错误" + e);
          }
        })
        .then(() => {
          this.deleteModal = false;
          this.deleting = false;
          this.refreshUnitCodes();
        });
    },
    createUnitCode: function () {
      this.newUnitCodeForm;
      const data = {
        unit_name: this.newUnitCodeForm.unit_name,
        unit_code: this.newUnitCodeForm.unit_code
      };
      this.axios
        .post(
          `api/campus/${this.campus}/faculty/${this.selectedFaculty}/units`,
          data
        )
        .then(resp => {
          this.$Message.success("创建成功");
          this.createUnitCard = false;
          this.refreshUnitCodes(this.newUnitCodeForm.unit_code);
        })
        .catch(e => {
          if (e.status_code == 409) {
            this.$Message.error("科目已存在");
          } else {
            this.$Message.error("创建失败: " + e);
          }
        });
    },
    createCourseType: function () {
      if (
        this.newCourseTypeForm.course_type == null ||
        this.newCourseTypeForm.type_code == null
      ) {
        this.$Message.error("课程类型信息不能为空");
        return;
      }
      const data = {};
      data.course_type = this.newCourseTypeForm.course_type;
      data.type_code = this.newCourseTypeForm.type_code;
      this.axios
        .post(`api/campus/${this.campus}/course_types`, data)
        .then(resp => {
          this.$Notice.success({
            title: "创建成功",
            desc: `${data.type_code}创建成功`
          });
          this.courseTypes = resp.data;
          this.createTypeCard = false;
        })
        .catch(error => {
          if (error.response.status == 409) {
            error = "课程类型已存在";
          }
          this.$Notice.error({ title: "创建课程类型失败", desc: error });
        });
    },
    deleteCourseType: function () {
      this.$Modal.confirm({
        title: "删除课程类型",
        content: `是否确认删除课程类型${this.selectedCourseType}`,
        onOk: () => {
          this.axios
            .delete(
              `api/campus/${this.campus}/course_type/${this.selectedCourseType}`
            )
            .then(resp => {
              this.$Notice.success({
                title: "删除成功",
                desc: `${this.selectedCourseType}删除成功`
              });
              this.selectedCourseType = "";
              this.courseTypeCard = false;
              this.refreshCourseTypes();
            })
            .catch(error => {
              this.$Notice.error({ title: "删除失败", desc: error });
            });
        }
      });
    }
  },
  mounted: function () {
    this.axios.get("api/sessions").then(resp => {
      const campus = resp.data.campus;
      this.campus = campus;
      // Only needs to load the first tab (On demand);
      this.refreshSemesters();
    });
  }
};
</script>
